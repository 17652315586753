import ApiService from "../../../services/api.service.js";
import JWTService from "../../../utils/Jwt.js";
import Cookies from "js-cookie";
import indexdb from "../../../utils/indexdb.js";

export default {
  async login(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/login/", payload)
        .then(function(response) {
          if (response.status === 200) {
            const tokens = response.data.tokens;

            let user_id = JWTService.decodeJWT(tokens.access);

            // Set the access token and user_id in the Cookies
            Cookies.set("user_id", user_id, { expires: 365 });
            Cookies.set("access_token", tokens.access, { expires: 365 });
            Cookies.set("refresh_token", tokens.refresh);
            // Cookies.set('is_paid', response.data.is_paid)
            context.commit(
              "setUserFirstLoginAttemp",
              response.data.first_login_attempt
            );
            context.commit("setUser", {
              accessToken: tokens.access,
              user_id: user_id,
            });
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async signUp(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/create/", payload)
        .then(function(response) {
          if (response.status === 201) {
            context.commit("setEmail", payload.email);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async organizationSignUp(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/organization-account/create/", payload)
        .then(function(response) {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async checkIsAutheticated() {
    await ApiService.get("user/me/")
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async requestDemo(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("demo/create/", payload)
        .then(function(response) {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async logout(context, accessToken=null) {
    const requestObj = {
      refresh: Cookies.get("refresh_token"),
    };
    ApiService.setAuthorizationHeader(accessToken);
    await ApiService.post("user/logout/", requestObj)
      .then(() => {
        context.commit("setUser", { accessToken: null, user_id: null });
        context.commit("profile/setLoggedInUserDetails", [], { root: true });
        context.commit("profile/setUsersCategories", [], { root: true });
        context.dispatch("notification/closeNotificationSocket", {}, {root: true})
      })
      .catch((err) => {
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        Cookies.remove("user_id");
        context.commit("setUser", { accessToken: null, user_id: null });
      });
  },
  async validateEmail(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("validate/email/", payload)
        .then(function(response) {
          if (response.status === 200) {
            context.commit("setPersonalInfo", payload);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async setPasswordDetail(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("validate/password/", payload)
        .then(function(response) {
          if (response.status === 200) {
            context.commit("setPasswordInfo", payload);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  lockout(context, payload) {
    context.commit("setLockoutPassword", payload);
    return "success";
  },
  getSignupArticles() {
    return new Promise((resolve, reject) => {
      ApiService.get("articles")
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async accountDeactivate(context) {
    ApiService.setAuthorizationHeader();
    await ApiService.post("user/deactivate/")
      .then(() => {
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        Cookies.remove("user_id");
        context.commit("setUser", { accessToken: null, user_id: null });
      })
      .catch((err) => {
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        Cookies.remove("user_id");
        context.commit("setUser", { accessToken: null, user_id: null });
      });
  },

  async accountActivate(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch("user/activate/", payload)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            // const tokens = response.data.tokens;
            // let user_id = JWTService.decodeJWT(tokens.access);
            // context.commit("setUser", {
            //   accessToken: tokens.access,
            //   user_id: user_id,
            // });
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async emailVerification(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`user/email-verify/?token=${payload.token}`)
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async verifyResetPassword(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/password-reset-verify/", payload)
        .then(function(response) {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async setNewPassword(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/password-reset-confirm/", payload)
        .then((response) => {
          if (response.status == 200) {
            console.log(response);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async getSubscriptionPackages(context, _1) {
    return new Promise((resolve, reject) => {
      ApiService.get(`subscriptions/`)
        .then(function(response) {
          if (response.status === 200) {
            context.commit("setSubscriptionPlans", response.data);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async paymentCheckout(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `payment/checkout/${payload.price_id}?email=${payload.email}`
      )
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async inviteOnGlocal(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.post(`user/invite-to-glocal/`, payload)
        .then((response) => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getPackages(_, _1) {
    return new Promise((resolve, reject) => {
      ApiService.get(`get-subscription/`)
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async cancelGlocalSubscription(_, _1) {
    return new Promise((resolve, reject) => {
      ApiService.get(`cancel-subscription/`)
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async firstLoginAttempt(_, _1) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/login-attempt/")
        .then(function(response) {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async loginViaGoogle ({commit}, payload){
    return new Promise((resolve, reject) => {
      ApiService.post("social-auth/google/login/", payload)
        .then(function(response) {
          if (response.status === 200) {
            const tokens = response.data.tokens;

            let user_id = JWTService.decodeJWT(tokens.access);

            // Set the access token and user_id in the Cookies
            Cookies.set("user_id", user_id, { expires: 365 });
            Cookies.set("access_token", tokens.access, { expires: 365 });
            Cookies.set("refresh_token", tokens.refresh);
            // Cookies.set('is_paid', response.data.is_paid)
            commit(
              "setUserFirstLoginAttemp",
              response.data.first_login_attempt
            );
            commit("setUser", {
              accessToken: tokens.access,
              user_id: user_id,
            });
            resolve(response);
          }
        })
        .catch(function(error) {
          console.log(error)
          reject(error);
        });
    });
  },
  async loginViaLinkedin({commit}, payload){
    return new Promise((resolve, reject) => {
      ApiService.post("social-auth/linkedin/login/", payload)
        .then(function(response) {
          if (response.status === 200) {
            const tokens = response.data.tokens;

            let user_id = JWTService.decodeJWT(tokens.access);

            // Set the access token and user_id in the Cookies
            Cookies.set("user_id", user_id, { expires: 365 });
            Cookies.set("access_token", tokens.access, { expires: 365 });
            Cookies.set("refresh_token", tokens.refresh);
            // Cookies.set('is_paid', response.data.is_paid)
            commit(
              "setUserFirstLoginAttemp",
              response.data.first_login_attempt
            );
            commit("setUser", {
              accessToken: tokens.access,
              user_id: user_id,
            });
            resolve(response);
          }
        })
        .catch(function(error) {
          console.log(error)
          reject(error);
        });
    });
  },
  setLinkedInLoginRedirectionFlag({commit}, flag){
    commit('SET_LINKEDIN_LOGIN_REDIRECTION', flag)
  },
  verifyOTP(context, {email, otp}){
    return new Promise((resolve, reject)=>{
      ApiService.put(`otp-verification/${email}/verify/`, {otp}).then(res=>{
        const tokens = res.data.tokens;

            let user_id = JWTService.decodeJWT(tokens.access);

            // Set the access token and user_id in the Cookies
            Cookies.set("user_id", user_id, { expires: 365 });
            Cookies.set("access_token", tokens.access, { expires: 365 });
            Cookies.set("refresh_token", tokens.refresh);
            // Cookies.set('is_paid', response.data.is_paid)
            context.commit(
              "setUserFirstLoginAttemp",
              res.data.first_login_attempt
            );
            context.commit("setUser", {
              accessToken: tokens.access,
              user_id: user_id,
            });
        resolve(res)
      }).catch(err=>{reject(err)})
    })
  },
  sendOTP({state}, email){
    state.signupUserEmail = email
    return new Promise((resolve, reject)=>{
      ApiService.post(`otp-verification/${email}/resend/`).then(res=>{
        state.signupUserEmail = email
        resolve(res)
      }).catch(err=>{reject(err)})
    })
  },
  async addLinkedAccount({state}, account){
    let previousLinkedAccounts = await indexdb.getDbData('linkedAccounts')
    // console.log("PREVIOUS0", previousLinkedAccounts, Array.isArray(previousLinkedAccounts), typeof previousLinkedAccounts)
    if (Array.isArray(previousLinkedAccounts)){
      const isAccountExists = previousLinkedAccounts?.find(previousAccount=>{previousAccount.id === account?.id})
      const accountIndex = previousLinkedAccounts?.findIndex(previousAccount=>{previousAccount.id === account?.id})
      if (!isAccountExists){
          previousLinkedAccounts.push({...account, access_token: Cookies.get('access_token')})
          // console.log("PREVIOUS1", previousLinkedAccounts)
      }
      else{
        previousLinkedAccounts[accountIndex] = {...previousLinkedAccounts[accountIndex], access_token: Cookies.get('access_token')}
        // console.log("PREVIOUS2", previousLinkedAccounts, accountIndex)
      }
    }else{
      // console.log("PREVIOUS3", previousLinkedAccounts)
      previousLinkedAccounts = []
      previousLinkedAccounts.push({...account, access_token: Cookies.get('access_token')})
    }

    await indexdb.saveToDb('linkedAccounts', previousLinkedAccounts)
    state.linkedAccounts = previousLinkedAccounts
  },
  async getLinkedAccounts({state}){
    let previousLinkedAccounts = await indexdb.getDbData('linkedAccounts')
    state.linkedAccounts = previousLinkedAccounts
  },
  async activateAccount({commit}, accountId){
    let previousLinkedAccounts = await indexdb.getDbData('linkedAccounts')
    const account = previousLinkedAccounts?.find(previousAccount=>previousAccount.id === accountId)
    const access_token = account?.access_token
    if (access_token){
      let user_id = JWTService.decodeJWT(access_token);
      Cookies.set("user_id", user_id, { expires: 365 });
      Cookies.set("access_token", access_token, { expires: 365 });
      commit("setUser", {
        accessToken: access_token,
        user_id: user_id,
      });
    }
  },
  async removeLinkedAccount({state}, userId){
    await indexdb.deleteFromDb('linkedAccounts', userId)
    state.linkedAccounts = state.linkedAccounts.filter(accounts=>accounts.id !== userId)
  }
};


